<template>
  <Header ref="header" />
  <div class="relative" ref="wrapper" >
    <router-view  v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <keep-alive v-bind:max="2">
          <component :is="Component"/>
        </keep-alive>
      </transition>
    </router-view>
    
  </div>
  <Footer ref="footer"/>  
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill();

export default {
  name: 'App',
  components: { Header, Footer},

  watch:{
    $route (){
      this.setWrapperHeight()
    }
  },

  mounted() {
    this.$nextTick(function() {
      this.setWrapperHeight()
    })
  },

  methods: {
    getHeaderHeight() {
      let height = this.$refs.header.$el.clientHeight;
      this.headerHeightLoaded = true;
      return `${height}px`;
    },
    getFooterHeight() {
      let height = this.$refs.footer.$el.clientHeight;
      return `${height}px`;
    },
    setWrapperHeight() {
      let styles = {
        minHeight: `calc(100vh - ${this.getHeaderHeight()})`,
        marginTop: this.getHeaderHeight()
      }
      if (this.$route.fullPath === '/about-me') {
        // styles.height = `calc(100vh - (${this.getFooterHeight()} + ${this.getHeaderHeight()}))`;
        styles.minHeight = null;
        styles.display = 'flex';
        styles.flexGrow = '1';
      } else {
        styles.height = null;
      }
      setTimeout(() => {
        Object.keys(styles).forEach((key) => {  
          this.$refs.wrapper.style[key] = styles[key];
         })
      }, 700);
    },
  }

}
</script>

<style lang="scss">

#app {
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: -webkit-fill-available;
}

* {
    outline: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

html, body {
    margin:0;
    padding:0;
    width:100%;
    height:100%;
  }

  html {
    color: #000;      
    font-weight: 400;
  }

  .fade-enter-active,
  .fade-leave-active {
    height: inherit;
    transition: opacity 0.6s ease-in-out;
  }


  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>
