import {createRouter, createWebHistory} from 'vue-router'
import Projects from '../components/Projects.vue'
import About from '../components/About.vue'


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: Projects
        },{
            path: '/about-me',
            component: About,
            name: 'About'
        },
    ]
})

export default router
