<template>
    <div class="carousel" ref="carousel">
      <div :ref="setSlideRefs" :description="slide.fields.description" :id="index + 1" v-for="(slide, index) in slideImages" :key="slide" class="slide w-full flex flex-col justify-between" >
        <img :height="slide.fields.file.details.image.height" :src="`https:${slide.fields.file.url}`" :alt="slide.fields.description">
      </div>
    </div>
    
    <div v-if="slideImages.length > 1" @click="previousSlide" :class="`arrow prev ${customCursor}`"></div>
    <div v-if="slideImages.length > 1" @click="nextSlide" :class="`arrow next ${customCursor}`"></div>
</template>

<script>
export default {
    emits: ['slideChanged'],
    name: 'ImageSlider',
    props:{
      images: Array,
      chosenCursor: String
    },
    data () {
      return {
        slideImages: this.images,
        slideBy: null,
        observer: null,
        slideRefs: [],
        currentSlide: null,
        customCursor: this.chosenCursor
      }
    },
        
    mounted() {
      this.slideBy = this.slideRefs[0].clientWidth,
      this.observer = new IntersectionObserver(this.thresholdInterceptor, {root: this.carousel, threshold: [0.8]}),
      this.observeSlides()
    },

    methods: {
      setSlideRefs(el) { if (el) { this.slideRefs.push(el) } },
      observeSlides() { this.slideRefs.map((el) =>this.observer.observe(el)) },

      thresholdInterceptor(entry) {
        let id = entry[0].target.id;
        if (this.currentSlide === id) {return}
        this.currentSlide = entry.intersectionRatio > 0 ? entry[0].target.id : id;
        this.$emit('slideChanged', {slide: this.currentSlide, slideDescription: entry[0].target.attributes[0].nodeValue});
      },

      nextSlide(){
        let slideBy = this.currentSlide == this.slideRefs.length  ? -(this.slideBy * this.slideRefs.length) : this.slideBy // Go back to start from last
        this.$refs.carousel.scrollBy({ left: slideBy, top: 0, behavior: 'smooth' })
      },

      previousSlide(){
        this.$refs.carousel.scrollBy({left: -this.slideBy, top: 0, behavior: 'smooth' })
      }
    }
}

</script>

<style scoped lang="scss">
.carousel {
  @media (min-width: 1024px) { z-index: 0; }
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  > div {
    flex: 0 0 auto;
    scroll-snap-align: center;
  }
}

.slide {
  img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.arrow {
  z-index: 10;
  display: none;
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  @media (min-width: 1024px) { display: block; }
  &.next{
    left: 50%;
    &:hover {
       &.black-stroke {cursor: url('../../assets/custom_cursors/next-black-stroke.svg') 5 5, auto; }
       &.white-stroke {cursor: url('../../assets/custom_cursors/next-white-stroke.svg') 5 5, auto; }
       &.black-fill {cursor: url('../../assets/custom_cursors/next-black-fill.svg') 5 5, auto; }
       &.white-fill {cursor: url('../../assets/custom_cursors/next-white-fill.svg') 5 5, auto; }
    }
  }
  &.prev{
    right: 50%;
    &:hover {
      &.black-stroke {cursor: url('../../assets/custom_cursors/prev-black-stroke.svg') 5 5, auto; }
      &.white-stroke {cursor: url('../../assets/custom_cursors/prev-white-stroke.svg') 5 5, auto; }
      &.black-fill {cursor: url('../../assets/custom_cursors/prev-black-fill.svg') 5 5, auto; }
      &.white-fill {cursor: url('../../assets/custom_cursors/prev-white-fill.svg') 5 5, auto; }
    }
  }
}


</style>
