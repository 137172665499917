<template>
  <nav ref="header" class="w-full py-4 lg:py-6 px-5 lg:px-20 font-masion-medium">
    <div class="flex items-center justify-between blurrer">
      <router-link to="/" class="cursor-pointer">
        <img alt="Logo" src="../assets/rr-logo.gif" width="50" height="50">
      </router-link>
      <router-link v-if="isHome" to="/about-me" class="cursor-pointer" data-content="Information">
        Information
      </router-link>
      <router-link v-if="!isHome" to="/" class="cursor-pointer" data-content="Back">Back</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    isHome() {
      return this.$route.fullPath === '/'
    }
  }
}
</script>

<style scoped lang="scss">

nav {
  position: fixed;
  top:0;
  z-index: 1001;
  background-color: rgba(255, 255, 255, .7);
  backdrop-filter: blur(40px);
}

</style>
