import { createApp } from 'vue'
import contentfulapi from './contentfulapi'

import App from './App.vue'
import router from './router/'
import './index.scss'

const app = createApp(App)
app.config.globalProperties.$contentapi = contentfulapi;
app.use(router)
app.mount('#app')
