<template>
  <div class="information vis-hidden flex flex-col space-y-4 py-4 px-5 justify-center font-book">
      <p class="info-text" v-for="content in caseStudyInfo.content" :key="content">{{content.content[0].value}}</p>
      <p class="info-text">Full case study available on request.</p>
  </div>
</template>

<script>
export default {
  name: 'Information',
  props: { content: Object },
  data() {
    return { caseStudyInfo: this.content }
  }
}
</script>

<style scoped lang="scss">
h1 {
  font-weight: 700;
}
.information {
    transition: opacity .7s ease-in-out;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left:0;
    opacity: 0;
    background-color: rgba(6, 6, 6, 0.95);
    color: #fff;
    &.active {
      transition: opacity .7s ease-in-out;
      opacity: 1;
      visibility: visible;
      z-index: 1000;
    }
    &.vis-hidden {
      visibility: hidden;
    }
}

</style>
