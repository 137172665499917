<template>
    <a :href="link" target="_blank" :data-content="linkText">
       {{linkText}}
    </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
      link: String,
      linkText: String,
      speed: {
        type: String,
        default: 'slow'
      }
  }
}
</script>

<style lang="scss">
a {
  position: relative;
  display: inline-block;
  width: max-content;
  text-decoration: none;
  &::before {
    content: attr(data-content);
    position: absolute;
    color: #f4461e; 
    left: 0;
    width: 0;
    transition: width .3s;
    overflow: hidden;
    white-space: nowrap;
  }
  &:hover {
      &::before {
        width: 100%;
      }
  }
}
</style>
