<template>
    <div class="project lg:px-20 mx-auto">
        <div class="whitespace">
        </div>
        <div class="summary flex flex-col justify-end w-full px-4 pb-2 lg:px-0 lg:pb-0 ">
            <p class="lg:w-5/6 bold font-demi">{{title}}</p>  
            <p class="w-full lg:w-5/6 font-book">{{description}}</p>
        </div>

        <div class="slider">
            <ImageSlider :images="imageGallery" :chosenCursor="cursorSelection" @slideChanged="updateSlideInfo"/>
            <Information ref="information" :content="information"/>
        </div>
        <div class="information px-4 lg:px-0 font-book">
            <div class="flex justify-between items-baseline space-x-0 pt-2 lg:pt-4">
                <div class="info-icon cursor-pointer self-center" @click="toggleInfo" ref="infoIcon">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="28" height="28" rx="14" stroke="black" stroke-width="2"/>
                        <path d="M14.6449 9.558V8.07H16.1489V9.558H14.6449ZM11.8769 12.39V11.27H16.0369V18.63H18.8369V19.75H11.7969V18.63H14.7409V12.39H11.8769Z" fill="black"/>
                        <path d="M14.6449 9.558H14.3949V9.808H14.6449V9.558ZM14.6449 8.07V7.82H14.3949V8.07H14.6449ZM16.1489 8.07H16.3989V7.82H16.1489V8.07ZM16.1489 9.558V9.808H16.3989V9.558H16.1489ZM11.8769 12.39H11.6269V12.64H11.8769V12.39ZM11.8769 11.27V11.02H11.6269V11.27H11.8769ZM16.0369 11.27H16.2869V11.02H16.0369V11.27ZM16.0369 18.63H15.7869V18.88H16.0369V18.63ZM18.8369 18.63H19.0869V18.38H18.8369V18.63ZM18.8369 19.75V20H19.0869V19.75H18.8369ZM11.7969 19.75H11.5469V20H11.7969V19.75ZM11.7969 18.63V18.38H11.5469V18.63H11.7969ZM14.7409 18.63V18.88H14.9909V18.63H14.7409ZM14.7409 12.39H14.9909V12.14H14.7409V12.39ZM14.8949 9.558V8.07H14.3949V9.558H14.8949ZM14.6449 8.32H16.1489V7.82H14.6449V8.32ZM15.8989 8.07V9.558H16.3989V8.07H15.8989ZM16.1489 9.308H14.6449V9.808H16.1489V9.308ZM12.1269 12.39V11.27H11.6269V12.39H12.1269ZM11.8769 11.52H16.0369V11.02H11.8769V11.52ZM15.7869 11.27V18.63H16.2869V11.27H15.7869ZM16.0369 18.88H18.8369V18.38H16.0369V18.88ZM18.5869 18.63V19.75H19.0869V18.63H18.5869ZM18.8369 19.5H11.7969V20H18.8369V19.5ZM12.0469 19.75V18.63H11.5469V19.75H12.0469ZM11.7969 18.88H14.7409V18.38H11.7969V18.88ZM14.9909 18.63V12.39H14.4909V18.63H14.9909ZM14.7409 12.14H11.8769V12.64H14.7409V12.14Z" fill="black"/>
                    </svg>
                </div>

                <transition name="slide-fade" mode="out-in">
                    <p :key="currentSlideDescription" class="flex-grow pl-3 pt-1 lg:pt-2">{{currentSlideDescription}}</p>
                </transition>
                <p class="md:space-x-1">
                    <span class="pt-1" >{{currentSlide}}</span>
                    <span class="pt-1" >/</span> 
                    <span class="pt-1" >{{slidesLength}}</span>
                </p>
            </div>
        </div>
    </div>

</template>

<script>
import ImageSlider from './ImageSlider.vue'
import Information from './Information.vue'

export default {
  name: 'Project',
  components: {
    ImageSlider,
    Information
  },
  props: {
    caseStudy: Object,
  },

  data(){
    return {
      title: this.caseStudy.title,
      description: this.caseStudy.description,
      imageGallery: this.caseStudy.imageGallery,
      cursorSelection: this.caseStudy.cursorSelection,
      currentSlide: '01',
      currentSlideDescription: '',
      slidesLength: String(this.caseStudy.imageGallery.length).padStart(2, '0'),
      information: this.caseStudy.information,
      infoActive: false
    }
  },

  watch:{
    $route (){ if (this.infoActive) return this.toggleInfo() }
  },
  
  methods: {
    updateSlideInfo(payload){
        this.currentSlide = String(payload.slide).padStart(2, '0');
        this.currentSlideDescription = String(payload.slideDescription).padStart(2, '0');
    },

    toggleInfo() {
        this.infoActive = !this.infoActive;
        this.$refs.information.$el.classList.toggle('active');
        this.$refs.infoIcon.classList.toggle('active');
        if (this.$refs.information.$el.classList.contains('vis-hidden')) {
            this.$refs.information.$el.classList.remove('vis-hidden');
        } else {
            setTimeout(() => this.$refs.information.$el.classList.add('vis-hidden'), 750);
        }
    }
  }
}
</script>

<style scoped lang="scss">

.project {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content auto max-content;
    @media (min-width: 1024px) {
        z-index: 0;
        grid-column-gap: 10%;
        grid-template-columns: 25% 65%;
        grid-template-rows: auto auto auto;
    }
}
.summary .whitespace .information {
    @apply px-4;
    @media (min-width: 1024px) { @apply px-0  }
}
.whitespace{
    display: none;
    @media (min-width: 1024px) {
        display: flex;
        grid-area: 1 / 1;
    }
}
.summary {
    grid-area: 1 / 1;
    @media (min-width: 1024px) {
        grid-area: 2 / 1 / 4 / 2;
    }
}

.slider {
    position: relative;
    max-height: 73vh;
    height: auto;
    grid-area: 2 / 1;
    @media (min-width: 1024px) {
        grid-area: 1 / 2 / 3 / 2;
    }
}

.information {
    grid-area: 3 / 1;
     @media (min-width: 1024px) {
        grid-area: 3 / 2;
    }
}

.info-icon {

    svg {
        fill: white;
        path {
            fill: black;
        }
        transition: fill .7s ease-in-out;
        width: 20px;
        height: 20px;
        @media (min-width: 1024px) {
            width: auto;
            height: auto;
        }
    }
    
    &.active {
        svg {
            transition: fill .7s ease-in-out;
            fill: black;
            path {
                fill: white;
            }
       }
    } 
   
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s linear;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}

</style>
