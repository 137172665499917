<template>
  <div class="pt-3 md:pt-0">
    <div class="space-y-24 lg:space-y-36 mb-10 lg:mb-20 lg:pt-5 lg:mb-16">
      <Project v-for="caseStudy in caseStudies" :key="caseStudy.fields.title" :caseStudy="caseStudy.fields" />
    </div>
    <div class="lg:px-20 mb-10 lg:mb-20 back-to-top font-demi bold">
      <h4 @click="scrollTop" class="lg:col-start-2 cursor-pointer"> Back to the top</h4>
    </div>
  </div>
</template>

<script>
import Project from '../components/Project/Project.vue'
export default {
  name: 'Home',
  components: {
      Project
  },
  data() {
    return {
      caseStudies: null
    }
  },

  async beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      const response = await this.$contentapi.getEntries({order: '-sys.updatedAt'});
      this.caseStudies = response.items;
    },
    scrollTop() {
      console.log(window);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // document.getElementById('app').scrollIntoView({behavior: 'smooth'})
    }
  }
}
</script>

<style scoped lang="scss">
  
  .back-to-top {
    text-align: left;
    display: grid;
    grid-template-columns: auto;
    place-items: center;
    @media (min-width: 1024px) {
      justify-items: start;
      grid-template-columns: 35% 65%;
    }
  }
</style>
