<template>
  <footer class="w-full px-5 lg:px-20 py-4 lg:py-6 font-maison-mono">
    <div class="flex justify-between items-center ">
      <div class="space-x-1 lg:space-x-2 flex items-center">
        <p>{{currentYear}}</p>
        <p>Ryan</p>
        <p>Robinson</p>
      </div>
      <div class="flex items-center space-x-3 lg:space-x-8">
        <Link link="mailto:ryan7robinson@hotmail.com" linkText="Email"   />
        <Link link="https://www.instagram.com/ryanrobinson_94/" linkText="Instagram"   />
        <Link link="https://www.linkedin.com/in/ryan-robinson-3b113971/" linkText="LinkedIn"   />
        <Link link="https://yeahyeahcool.com" linkText="YYC"   />
      </div>
    </div>
  </footer>
</template>

<script>
import Link  from './UI/Link.vue'
export default {
  name: 'Footer',
  components: { Link },
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
  footer {
    color:#fff;
    background-color: #000;
  }
</style>
