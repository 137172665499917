<template>
<div class="about px-5 lg:px-20 font-book" >
    <div class="mb-4 lg:row-start-2">
        <h2 class="font-demi bold">About</h2>
    </div>

    <div class="lg:row-start-2 space-y-4 lg:space-y-10">
        <p>27 year old London-based designer and creative with more than 4 years experience, working on brands such as Rolls-Royce, Mclaren, O2 and Laurent Perrier. Full portfolio and references are available on request. Currently available for freelance</p>

        <div class="flex flex-col">
            <h4 class="font-demi bold">Mentions</h4>
            <Link link="https://www.strongisland.co/2016/09/17/hip-hop-politics-authority-closing-reception/" linkText="Strong Island" />
            <Link link="https://www.thearena.org.uk/in-the-arena-with/ryan-robinson" linkText="The Arena" />
        </div>

        <div class="flex flex-col">
            <h4 class="font-demi bold">Find me</h4>
            <Link link="mailto:ryan7robinson@hotmail.com" linkText="Email" />
            <Link link="https://www.instagram.com/ryanrobinson_94/" linkText="Instagram" />
            <Link link="https://www.linkedin.com/in/ryan-robinson-3b113971/" linkText="LinkedIn" />
            <Link link="https://yeahyeahcool.com" linkText="YYC" />
        </div>
    </div>
</div>
</template>

<script>
import Link from './UI/Link'
export default {
  name: 'About',
  components: { Link },
  props: {
      height: String
  }
}
</script>

<style scoped lang="scss">
.about {
    width: 100%;
    height: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 1024px) { 
        display: grid;
        grid-template-rows: auto 55% auto;
        grid-template-columns: 35% 65%;
    }
    h2 { color: #f4461e; }
}
</style>
